// @flow

import React from 'react'
import queryString from 'query-string'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'

import { fetchLead, patchLead } from 'Actions'
import { Store } from 'Store'

import styles from './styles.module.scss'

import { withTranslation, Trans } from 'react-i18next'
import transDomain from './translations/index.translations'

import themes, { type Theme } from './themes/__supportedThemes'

type Props = {
    history: any,
    location: any,
    match: any,
    theme: Theme,
    t: any,
};

function Landing (props: Props) {
    const { dispatch } = React.useContext(Store)
    const { t } = props
    const [formState, setFormState] = React.useState(null)

    const handleSubmit = React.useCallback(async e => {
        e.preventDefault()
        setFormState('Downloading ...')

        try {
            let code = e.target.elements['code'].value
            await fetchLead(dispatch, code)
            const lead = await patchLead(dispatch, code, {}, true)

            const response = await fetch(lead.incentives.downloadable.url)
            if (!response.ok) {
                console.error(await response.text())
                alert('Unable to download playbook.')
                return
            }

            const blob = await response.blob()

            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = props.theme.fileName
            document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click()
            a.remove() // afterwards we remove the element again

            setFormState('Success')
        } catch (err) {
            console.error(err)
            setFormState(null)
        }
    }, [dispatch, props.theme.fileName])

    return (
        <div className={styles.container}>
            <aside className={styles.leftSide}>
                <a className={styles.logoContainer} href="https://shiftmarketing.io/" target="_blank"
                    rel="noopener noreferrer">
                    <div className={styles.logo}/>
                </a>

                <div style={{
                    backgroundImage: `url(${props.theme.picture})`,
                    maxWidth: props.theme.pictureWidth,
                    minWidth: props.theme.pictureMinWidth,
                    ...props.theme.customPictureStyle,
                }} className={styles.img}/>
            </aside>

            <div className={styles.rightSide}>

                <div className={styles.logoMobile}>
                    <a className={styles.logoContainer} href="https://shiftmarketing.io/" target="_blank"
                        rel="noopener noreferrer">
                        <div className={styles.logo}/>
                    </a>
                </div>

                <h1 className={styles.title}>
                    <Trans t={t} i18nKey={props.theme.titleTransFieldKey}>
                        Download your Factory Order campaign product sheets today<br /><strong>(it's free!).</strong>
                    </Trans>
                </h1>

                {props.theme.hasInformationText && (
                    <span className={styles.info}>
                        <Trans t={t} i18nKey={props.theme.informationTransFieldKey}>
                            Get a <strong> free </strong> product sheet with <br /> your waitlist reservation.
                        </Trans>
                    </span>
                )}

                <form className={styles.form} onSubmit={handleSubmit}>
                    <input
                        name="code"
                        type="text"
                        placeholder={t(props.theme.inputTransFieldKey)}
                        defaultValue={queryString.parse(window.location.hash)['code']}
                    />
                    <button disabled={formState !== null} className={cn({
                        [styles.loading]: formState,
                    })}>
                        {formState || t(props.theme.buttonTransFieldKey)}
                        <div style={{
                            backgroundImage: `url(${props.theme.download})`
                        }} className={styles.icon}/>
                    </button>
                </form>

                <div className={styles.information}>
                    <strong>{t(props.theme.benefitsHeadingTransFieldKey)}</strong>
                    {props.theme.benefits.map((benefit, i) => (
                        <p key={i}>{benefit}</p>
                    ))}
                </div>

                <footer className={styles.footer}>
                    <span>
                        {t('footer.line1')}
                    </span>
                    <span>
                        {t('footer.line2')}
                    </span>
                </footer>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(themes)(Landing))
